import DestinationBreadcrumbs from '$components/DestinationBreadcrumbs'
import Layout from '$components/Layout'
import Page from '$components/Page'
import PostCardRow from '$components/PostCardRow'
import SelectDestination from '$components/SelectDestination'
import Text from '$components/Text'
import { extractPostsFromAllMarkdownRemarkQueryByType } from '$utils/buildRelatedPosts'
import { Destination, DestinationType } from '$utils/countriesList'
import { Maybe, PostType } from '$utils/globals'
import { rhythm } from '$utils/typography'
import css from '@emotion/css'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import { Query } from 'graphqlTypes'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import { animated, useTransition } from 'react-spring'
import { oc } from 'ts-optchain'
import { mediaQueries } from '../theme/legacy'

const { useMemo, useCallback } = React

type Props = {
  data: Query
  pageContext?: {
    activeDestination?: Maybe<Destination>
  }
}

function Destinations(props: Props) {
  const defaultActiveDestination = oc(props).pageContext.activeDestination()

  const [activeDestination, setActiveDestination] = React.useState(
    defaultActiveDestination,
  )

  const handleDestinationChange = useCallback(
    function handleDestinationChange(destination: Destination) {
      if (!destination) {
        window &&
          window.history.pushState(null, 'Destinations', `/destinations`)
        setActiveDestination(null)
        return
      }

      window &&
        window.history.pushState(
          null,
          'Destinations',
          `/destinations/${destination.slug}`,
        )
      setActiveDestination(destination)
    },
    [setActiveDestination],
  )

  const posts = extractPostsFromAllMarkdownRemarkQueryByType({
    markdownData: props.data.allMarkdownRemark,
    type: PostType.Destination,
  })

  const { transitionData } = useMemo(
    () => {
      const allPosts = posts.filter(p => {
        let postCountry = oc(p)
          .country('')
          .toLowerCase()
        let pageCountry = ''

        if (!activeDestination) {
          return true
        }
        switch (activeDestination.type) {
          case DestinationType.Country: {
            return (
              oc(p)
                .country('')
                .toLowerCase() === activeDestination.name
            )
          }
          case DestinationType.Continent: {
            return (
              oc(p)
                .continent('')
                .toLowerCase() === activeDestination.name
            )
          }
          default: {
            return false
          }
        }
      })

      const returnPosts = [
        ...allPosts.filter(p => p && p.featured), // Move featured to top
        ...allPosts.filter(p => p && !p.featured),
      ].slice(0, 16)

      return {
        transitionData: [
          {
            key: activeDestination ? activeDestination.slug : 'all',
            posts: returnPosts,
          },
        ],
      }
    },
    [activeDestination, posts],
  )

  const transitions = useTransition(
    transitionData,
    item => {
      return item.key
    },
    {
      from: { opacity: 0, transform: `translateY(25px)`, height: 'auto' },
      enter: { opacity: 1, transform: `translateY(0px)`, height: 'auto' },
      leave: { opacity: 0, transform: `translateY(-25px)`, height: 0 },
      // config: { mass: 5, tension: 500, friction: 100 },
    },
  )

  return (
    <Layout>
      <Helmet title={'Destinations'} />
      <Page>
        <div
          css={css`
            margin: 0 0 ${rhythm(1)} 0;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            min-height: 188px;
            ${mediaQueries.MIN_DEFAULT_MEDIA_QUERY} {
              height: 400px;
            }
          `}
        >
          <div
            css={css`
              top: 0;
              left: 0;
              position: absolute;
              height: 100%;
              width: 100%;
              z-index: -1;
              border-radius: 7px;
              overflow: hidden;
            `}
          >
            <Image
              // @ts-ignore type inconsistency
              fluid={props.data.file.childImageSharp.fluid}
              css={css`
                height: 100%;
                filter: brightness(0.7);
              `}
            />
          </div>
          <div
            css={css`
              text-align: center;
              white-space: nowrap;
            `}
          >
            {' '}
            <Text
              size={Text.Sizes.ExtraLarge}
              weight={Text.Weights.Bolder}
              color={Text.Colors.PrimaryInverse}
            >
              <span
                css={css`
                  text-shadow: 1px 3px 26px rgba(0, 0, 0);
                  ${mediaQueries.DEFAULT_MEDIA_QUERY} {
                    font-size: 2rem;
                  }
                `}
              >
                Where will you go?
              </span>
            </Text>
          </div>
          <div
            css={css`
              width: 100%;
              max-width: 500px;
              padding: 0 20px;
            `}
          >
            <SelectDestination
              value={activeDestination}
              onChange={handleDestinationChange}
            />
          </div>
        </div>
        <DestinationBreadcrumbs destination={activeDestination} />
        <div
          css={css`
            position: relative;
          `}
        >
          {transitions.map(({ item, key, props }) => (
            <animated.div
              key={key}
              style={{
                ...props,
              }}
            >
              <div key={key}>
                <PostCardRow posts={item.posts} />
              </div>
            </animated.div>
          ))}
        </div>
      </Page>
    </Layout>
  )
}

export default Destinations

export const pageQuery = graphql`
  query GetDestinations {
    file(relativePath: { eq: "destinations-background.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1024, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { type: { eq: "destination" }, language: { eq: null } }
      }
      sort: { fields: frontmatter___publishedAt, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            featured
            slug
            path
            type
            visibility
            country
            city
            continent
            publishedAt
            title
            image {
              childImageSharp {
                fluid(
                  maxWidth: 600
                  maxHeight: 400
                  cropFocus: CENTER
                  quality: 50
                ) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`
