/** @jsx jsx */
import Text from '$components/Text'
import { Grays, Greens } from '$theme/colors'
import { mediaQueries } from '$theme/legacy'
import {
  Destination,
  DestinationType,
  getVisitedContinents,
  getVisitedCountries,
} from '$utils/countriesList'
import { Maybe } from '$utils/globals'
import { css, jsx } from '@emotion/core'
import * as React from 'react'
import Select from 'react-select'
import searchIconSVG from '../images/search-icon.svg'

export const SingleValue = ({ children, ...props }) => {
  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
      `}
    >
      <Text
        weight={Text.Weights.Bold}
        color={Text.Colors.Primary}
        size={Text.Sizes.Small}
      >
        {children}
      </Text>
    </div>
  )
}

export const Placeholder = ({ children, ...props }) => {
  return (
    <div
      css={css`
        height: 100%;
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
      `}
    >
      <span
        css={css`
          ${mediaQueries.MIN_TABLET_MEDIA_QUERY} {
            display: none;
          }
        `}
      >
        <Text
          weight={Text.Weights.Normal}
          color={Text.Colors.PrimaryLight}
          size={Text.Sizes.Small}
        >
          Search
        </Text>
      </span>
      <span
        css={css`
          display: none;
          ${mediaQueries.MIN_TABLET_MEDIA_QUERY} {
            display: inline;
          }
        `}
      >
        <Text
          weight={Text.Weights.Normal}
          color={Text.Colors.PrimaryLight}
          size={Text.Sizes.Small}
        >
          {children}
        </Text>
      </span>
    </div>
  )
}

const SearchIcon = () => (
  <img
    src={searchIconSVG}
    css={css`
      margin: 0 10px 0 0;
      width: 15px;
      height: 15px;
      transform: translateY(1px);
    `}
  />
)

export const ValueContainer = ({ children, ...props }) => (
  <span
    css={css`
      flex: 1;
      display: flex;
      align-items: center;
      padding: 0 20px;
    `}
  >
    <Text
      weight={Text.Weights.Bold}
      color={Text.Colors.Primary}
      size={Text.Sizes.Small}
    >
      <SearchIcon />
      <span
        css={css`
          ${mediaQueries.TABLET_MEDIA_QUERY} {
            display: none;
          }
        `}
      >
        I am going to
      </span>
    </Text>
    <span
      css={css`
        margin-left: 5px;
      `}
    >
      {children}
    </span>
  </span>
)

const Control = props => {
  const { children, isFocused, innerRef, innerProps } = props

  return (
    <div
      ref={innerRef}
      css={[
        // styles,
        css`
          width: 100%;
          border-radius: 32px;
          border: 1px solid ${isFocused ? Greens.Six : Grays.Five};
          height: 40px;
          display: flex;
          background: white;
          box-shadow: ${isFocused
            ? `1px 3px 26px ${Grays.Zero},inset 0 0 0 1px ${Greens.Six}`
            : `1px 3px 26px ${Grays.Zero}`};
          transition: box-shadow 250ms ease-out;
        `,
      ]}
      {...innerProps}
    >
      {children}
    </div>
  )
}

const Menu = props => {
  const { children, innerRef, innerProps } = props

  return (
    <div
      ref={innerRef}
      css={[
        css`
          margin-top: 5px;
          position: absolute;
          z-index: 9999;
          width: 100%;
        `,
      ]}
      {...innerProps}
    >
      {children}
    </div>
  )
}

export const Option = props => {
  const { isFocused, innerRef, innerProps, data } = props

  return (
    <div
      ref={innerRef}
      css={[
        css`
          background: ${isFocused ? Greens.Seven : 'white'};
          color: ${isFocused ? 'white' : 'currentColor'};
          padding: 5px 30px;
          cursor: pointer;
          transition: background-color 250ms ease, color 250ms ease;
        `,
      ]}
      {...innerProps}
    >
      <Text
        weight={Text.Weights.Bold}
        color={isFocused ? Text.Colors.PrimaryColored : Text.Colors.Primary}
        size={Text.Sizes.Small}
      >
        {data.label}
      </Text>{' '}
      <Text
        color={
          isFocused ? Text.Colors.PrimaryColored : Text.Colors.PrimaryLight
        }
        size={Text.Sizes.ExtraSmall}
      >
        {data.type}
      </Text>
    </div>
  )
}

type Option = {
  value: string
  name: string
  label: string
  type: DestinationType
  slug: string
}

type SelectBaseProps = {
  onChange: (e: any) => void
  value?: any
  options: Array<Option>
  isClearable?: boolean
}

export function SelectBase(props: SelectBaseProps) {
  const { options, onChange, value, isClearable } = props
  return (
    <div>
      <Select
        autofocus
        value={value}
        onChange={onChange}
        options={options}
        isClearable={isClearable}
        components={{
          ValueContainer,
          SingleValue,
          Placeholder,
          Control,
          Menu,
          Option,
          IndicatorSeparator: () => null,
          NoOptionsMessage: props => {
            return (
              <div
                css={css`
                  background: white;
                  padding: 5px 30px;
                `}
              >
                <Text size={Text.Sizes.Small}>
                  We have no published content for{' '}
                  <Text size={Text.Sizes.Small} weight={Text.Weights.Bold}>
                    "{props.selectProps.inputValue}"
                  </Text>
                  . 🤦🏻‍♂️🤷🏻‍♀️
                </Text>
              </div>
            )
          },
        }}
        styles={{
          input: styles => ({ ...styles, fontSize: Text.Sizes.Small }),
          menuList: styles => ({
            ...styles,
            borderRadius: 32,
            padding: 0,
            border: `1px solid ${Grays.Five}`,
            boxShadow: `1px 3px 26px rgb(0,0,0,.45)`,
          }),
          // placeholder: styles => ({ ...styles, fontSize: Text.Sizes.Small }),
        }}
        placeholder={'...search by continent or country'}
      />
    </div>
  )
}

type Props = {
  onChange: (value: Destination) => void
  value?: Maybe<Destination>
}

const sortAlphbetically = (a, b) => a.name.localeCompare(b.name)

export default function SelectDestination(props: Props) {
  const { onChange, value } = props
  const countries = getVisitedCountries()
  const continents = getVisitedContinents()

  countries.sort(sortAlphbetically)
  continents.sort(sortAlphbetically)

  const options = [...continents, ...countries]
  const [internalValue, set] = React.useState(value)

  return (
    <SelectBase
      options={options}
      value={internalValue}
      isClearable={true}
      onChange={(value: Destination) => {
        set(value)
        if (onChange) {
          onChange(value)
        }
      }}
    />
  )
}
