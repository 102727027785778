/** @jsx jsx */
import * as React from 'react'
import { Link as GatsbyLink } from 'gatsby'
import { css, jsx } from '@emotion/core'

import Text from '$components/Text'
import { Separator } from '$components/Breadcrumbs'
import {
  Destination,
  DestinationType,
  getVisitedContinents,
} from '$utils/countriesList'
import { rhythm } from '$utils/typography'

type Props = {
  destination: Destination | null | undefined
}

function DestinationBreadcrumbs(props: Props) {
  const { destination: d } = props

  if (!d) {
    return (
      <div
        css={css`
          margin-bottom: ${rhythm(1)};
        `}
      >
        <GatsbyLink to="/destinations">
          {' '}
          <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.Link}>
            Destinations
          </Text>
        </GatsbyLink>
        <Separator />
        <Text size={Text.Sizes.Small}> All </Text>
      </div>
    )
  }

  const visitedContinents = getVisitedContinents()
  const continent =
    d.type === DestinationType.Continent
      ? visitedContinents.find(c => c.value === d.value)
      : visitedContinents.find(c => c.value === d.continent)

  return (
    <div
      css={css`
        margin-bottom: ${rhythm(1)};
      `}
    >
      <GatsbyLink to="/destinations">
        <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.Link}>
          Destinations
        </Text>
      </GatsbyLink>
      {continent && (
        <React.Fragment>
          <Separator />
          <GatsbyLink to={`/destinations/${continent.slug}`}>
            <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.Link}>
              {continent.label}
            </Text>
          </GatsbyLink>
        </React.Fragment>
      )}

      {d.type === DestinationType.Country && (
        <React.Fragment>
          <Separator />
          <GatsbyLink to={`/destinations/${d.slug}`}>
            <Text size={Text.Sizes.Small} specialType={Text.SpecialTypes.Link}>
              {d.label}
            </Text>
          </GatsbyLink>
        </React.Fragment>
      )}
    </div>
  )
}
export default React.memo(DestinationBreadcrumbs)
