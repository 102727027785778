import { oc } from 'ts-optchain'
import * as countriesList from 'countries-list'
// Use relative path instead of path alias for node shit
import { replaceSpacesWithDashes } from './helpers'
const { countries, continents } = countriesList

// Make visited and add slug
const visitedCountries = [
  // Asia
  'PH',
  'TH',
  'KH',
  'VN',
  'JP',
  // 'MY',
  // 'SG',
  // South America
  'CL',
  'AR',
  'PE',
  // North America
  'US',
  'CU',
  // europe
  'BA',
  'AT',
  'HR',
  'HU',
  'ES',
  'NL',
]

export enum DestinationType {
  Country = 'country',
  Continent = 'continent',
}

export type Destination = {
  label: string
  name: string
  continent: string
  value: string
  slug: string
  type: DestinationType
}

export function getVisitedCountries(): Array<Destination> {
  const list = visitedCountries.map(country => ({
    label: countries[country].name,
    name: countries[country].name.toLowerCase(),
    continent: countries[country].continent,
    value: country,
    type: DestinationType.Country,
    slug: `${replaceSpacesWithDashes(
      continents[countries[country].continent],
    )}/${replaceSpacesWithDashes(countries[country].name)}`,
  }))
  list.sort((a, b) => {
    return a.label.localeCompare(b.label)
  })
  return list
}

export function getVisitedContinents() {
  const list = getVisitedCountries()
  const continentsPrefix = list.reduce((acc, country) => {
    acc.add(country.continent)
    return acc
  }, new Set<string>())

  return Array.from(continentsPrefix).map(c => {
    return {
      value: c,
      name: continents[c].toLowerCase(),
      label: continents[c],
      type: DestinationType.Continent,
      slug: replaceSpacesWithDashes(continents[c]),
    }
  })
}

export function getDestinationByContinent(continent: string) {
  const continents = getVisitedContinents()
  const destination = continents.find(c => c.value === continent)
  return destination
}
